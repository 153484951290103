/**
 * Removes electron symbols, if any found!
 *
 * Current web allocator gui (electron) uses a browser window with enabled node integration, to call
 * the web viewer inside this web app.
 *
 * This does not work together with jquery, as electron injects some symbols into the browser.
 * Therefore we have to remove them, before we load the jquery library.
 *
 * It is also a very bad idea (tm), to use electron to open an EXTERNAL web page with enabled node integration,
 * as this gives the external page full access to the hosts node runtime (e.g: file access, shell, etc.)!!!
 * see @https://electronjs.org/docs/faq#i-can-not-use-jqueryrequirejsmeteorangularjs-in-electron
 */
typeof require !== 'undefined' && (window.nodeRequire = require);
typeof window.require !== 'undefined' && delete window.require;
typeof window.exports !== 'undefined' && delete window.exports;
typeof window.module !== 'undefined' && delete window.module;
